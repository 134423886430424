/*
 Bulma Themeing
 @mixin ====================== */

// Fonts
$opensans: "Open Sans", Helvetica, sans-serif;
$ebgaramond: 'EB Garamond', 'Baskerville', serif;

$family-sans-serif: $opensans;
$family-serif: $ebgaramond;

$title-family: $opensans;
$subtitle-family: $opensans;
$family-primary: $family-serif;

// colors
$primary: #1d2a50;
$info: #ed4933;

@import "bulma";
