.navbar.is-transparent {
	position: absolute !important;
	top: 0;
	left: 0;
	right: 0;
	background-color: transparent !important;
	z-index: 1; }

.navbar {
	transition: all .2s ease-in-out; }

.has-sailor-background {
	background: linear-gradient(141deg, #0d1a2d88 0%, #1d2a5088 71%, #20276688 100%), url(/images/banner.jpg) !important;
	background-size: cover !important;
	background-blend-mode: multiply !important; }

.icons {
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding-top: 10px;
	padding-bottom: 20px;

	li {
		font-size: 40px;
		padding: 10px; } }
